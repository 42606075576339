<template>
  <div class="c-app flex-row align-items-center" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="4">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <!-- <img class="justify-content-center" src="@/assets/img/Raon_com_emoji1.png" style="max-width:100%;height:190px" /> -->
                  <p style="text-align:center;">
                  <img src="@/assets/img/loginLogo.png" style="max-width:100%" />
                  </p>
                  <CInput
                    v-model="user.id"
                    autocomplete="off"
                    placeholder="ID"
                    style="margin-top:15px"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    type="password"
                    v-model="user.password"
                    placeholder="Password"
                    autocomplete="off"
                    @keyup.enter="login()"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol >
                      <CButton block color="warning" variant="outline" class="px-1" @click="login()">Login</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <!-- <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
              </CCardBody>
            </CCard> -->
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
  import RSAKey from '@/js/rsa/rsa.js'
  import store from '../../store'
  export default {
    name: 'Login',
    // created() {
    //   this.tokenLogin();
    // },
    beforeRouteEnter(to, from, next) {
      const token = localStorage.getItem('Token');
      const loginId = localStorage.getItem('LoginId');

      if (token != null && loginId != null) {
        const params = {
          adminId: loginId,
          accessToken: token,
        };

        store.dispatch('loginStore/token', params)
          .then(() => next({ name: 'Dashboard' }))
          .catch(() => next());
      } else {
        next();
      }
    },
    data(){
      return {
        user: {
          id:'',
          password:''
        },
        admin:{
          adminId:'',
          adminKey:''
        },
        isOpenPasswordModal: false,
        pubRsaKey:[]
      }
    },
    mounted:function(){
      this.getRsaKey()
    },
    methods:{
      async login(){

        //TODO : 검증 로직 추가 필요

        if (this.user.id == '') {
          alert('아이디를 입력해주시기 바랍니다.');
          return;
        }
        if (this.user.password == '') {
          alert('패스워드를 입력해주시기 바랍니다.');
          return;
        }

        const rsa = new RSAKey()
        rsa.setPublic(this.pubRsaKey.publicKeyModulus, this.pubRsaKey.publicKeyExponent)

        let params = {
          id: this.user.id,
          pswd: rsa.encrypt(this.user.password)
        }
        try{
          await this.$store.dispatch("loginStore/login", params)

          // 최초 로그인 여부 확인 (최초 로그인시 비밀번호 변경 화면)
          if(this.$store.state.loginStore.fisrtTimeLoginYn){
            window.setTimeout(()=>{
              this.$router.push({name: 'InitPasswordChange'})
            }, 500)
          }else{
            //TODO: 뒤로가기 대응
            window.setTimeout(()=>{
              this.$router.push({name: 'Dashboard'})
            }, 500)
          }
        }catch(error){
          alert(error.errorMessage)
          this.getRsaKey() //로그인실패시 공개키 재호출

        }
        // window.history.length > 2 ? this.$router.go(-1) :  this.$router.push({name: 'Dashboard'})

      },
      // async tokenLogin() {
      //   try {
      //     const token = localStorage.getItem('Token');
      //     const loginId = localStorage.getItem('LoginId');
      //     if (token != null && loginId != null) {
      //       let params = {
      //         adminId: loginId,
      //         accessToken: token
      //       }
      //       await this.$store.dispatch("loginStore/token", params)
      //
      //       // window.setTimeout(()=>{
      //         this.$router.push({name: 'Dashboard'})
      //       // }, 500)
      //
      //     }
      //   }catch (e) {
      //     // alert(error.errorMessage)
      //   }
      //
      // },
      async getRsaKey(){
        try{
          await this.$store.dispatch("loginStore/getRsaPubKey")
          this.pubRsaKey = this.$store.state.loginStore.pubKey
        }catch(error){
          console.log(error)
        }
      }
    }
  }
</script>
